import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Api } from '@partnerka-front/api';
import {
  Title,
  FormAuth,
  FormAfter,
  Paragraph,
  ButtonSubmit,
  InputRecovery,
  PageLoading,
  FullScreenSpinner,
  PageAuth,
  FooterAuth,
  ControlledInput,
  Message,
} from '@partnerka-front/components';
import { ResponseError } from '@partnerka-front/types';
import {
  regExpEmail,
  regExpEmailCharacter,
  regExpPasswordCharacter,
  useUser,
} from '@partnerka-front/utils';
import styled from 'styled-components';

const SendAgain = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

enum LoginSteps {
  login = 'login',
  activated = 'activated',
  successfully = 'successfully',
}

interface LoginPageProps {
  isBusiness?: boolean;
}

export function LoginPage({ isBusiness }: LoginPageProps) {
  const { t } = useTranslation(['auth', 'validation', 'errors']);
  const { uid, token, status } = useParams();
  const { login } = useUser();
  const navigate = useNavigate();

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(regExpEmailCharacter, `${t('validation.invalid_character', { ns: 'validation' })}`)
      .matches(regExpEmail, `${t('validation.incorrect_email', { ns: 'validation' })}`),
    password: Yup.string()
      .required()
      .matches(
        regExpPasswordCharacter,
        `${t('validation.invalid_character', { ns: 'validation' })}`
      )
      .min(
        8,
        `${t('validation.password_must_be_at_least_characters', {
          characters: '8',
          ns: 'validation',
        })}`
      )
      .max(
        64,
        `${t('validation.password_must_be_at_most_characters', {
          characters: '64',
          ns: 'validation',
        })}`
      ),
  });
  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const { handleSubmit, getValues } = formMethods;

  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(LoginSteps.login);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);
  const [errorActivation, setErrorActivation] = useState<ResponseError | null>(null);
  const [successResend, setSuccessResend] = useState(false);

  const submit = handleSubmit(async (data) => {
    setPending(true);
    setSuccessResend(false);
    await login(data, setError);
    setPending(false);
  });

  const activation = async (uid: string, token: string) => {
    Api.activation({
      uid,
      token,
    })
      .then(() => setStep(LoginSteps.activated))
      .catch(setErrorActivation);

    setLoading(false);
    navigate('/login');
  };

  const resendActivation = async () => {
    setPending(true);
    await Api.resendActivation({
      email: getValues('email'),
    })
      .then(() => {
        setError(null);
        setSuccessResend(true);
      })
      .catch((err) => {
        setError(err);
      });
    setPending(false);
  };

  useEffect(() => {
    if (error && errorActivation) {
      setErrorActivation(null);
    }
  }, [error]);

  useEffect(() => {
    uid && token ? activation(uid, token) : setLoading(false);
    if (status === 'successfully') {
      setStep(LoginSteps.successfully);
      navigate('/login');
    }
  }, []);

  if (loading) return <FullScreenSpinner />;

  return (
    <PageAuth>
      <FormProvider {...formMethods}>
        {pending && <PageLoading />}
        <FormAuth onSubmit={submit} isBusiness={isBusiness}>
          {step === LoginSteps.login && (
            <div>
              <Title>{t('auth.login_title', { ns: 'auth' })}</Title>
            </div>
          )}
          {step === LoginSteps.activated && (
            <>
              <Title>{t('auth.congratulate_title', { ns: 'auth' })}</Title>
              <Paragraph>{t('auth.congratulate_description', { ns: 'auth' })}</Paragraph>
            </>
          )}
          {step === LoginSteps.successfully && (
            <div>
              <Title>{t('auth.password_changed_title', { ns: 'auth' })}</Title>
            </div>
          )}
          <ControlledInput name={'email'} label={'Email'} dataTestId={'email'} />
          <ControlledInput
            name={'password'}
            label={'Password'}
            type={'password'}
            afterForm={
              <InputRecovery>
                <Link to="/password-reset">{t('auth.forgot_your_password', { ns: 'auth' })}</Link>
              </InputRecovery>
            }
            dataTestId={'password'}
          />
          {errorActivation && (
            <Message onClose={() => setErrorActivation(null)}>
              {t('errors.activation_link_inactive', { ns: 'errors' })}
            </Message>
          )}
          {error?.response.data && (
            <Message onClose={() => setError(null)}>
              {error.response.data.detail === 'ACCOUNT_IS_NOT_BUSINESS' ||
              error.response.data.detail === 'LOG_IN_BUSINESS_PANEL' ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${t(`errors.${error.response.data.detail}`, { ns: 'errors' })}`,
                  }}
                />
              ) : (
                <p>
                  {error.response.data.detail
                    ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
                    : t('errors.try_again_later', { ns: 'errors' })}
                  {error.response.data.detail === 'ACCOUNT_CREATED_BUT_NOT_ACTIVE' && (
                    <>
                      {'. '}
                      <SendAgain onClick={resendActivation}>
                        {t('auth.send_again_activate', { ns: 'auth' })}
                      </SendAgain>
                    </>
                  )}
                </p>
              )}
            </Message>
          )}
          {successResend && (
            <Message variant={'success'} onClose={() => setSuccessResend(false)}>
              {t('auth.send_success', { ns: 'auth' })}
            </Message>
          )}
          <ButtonSubmit dataTestId={'login'} onClick={submit}>
            {t('auth.login', { ns: 'auth' })}
          </ButtonSubmit>
          <FormAfter>
            {t('auth.no_account', { ns: 'auth' })}{' '}
            <Link to="/registration">{t('auth.sign_up', { ns: 'auth' })}</Link>
          </FormAfter>
        </FormAuth>
      </FormProvider>

      {!isBusiness && <FooterAuth />}
    </PageAuth>
  );
}
