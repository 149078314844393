import { down } from 'styled-breakpoints';
import styled from 'styled-components';

interface AuthSectionProps {
  isBusiness?: boolean;
}

export const AuthSection = styled.section<AuthSectionProps>`
  width: 100%;
  max-width: 710px;
  margin: ${({ isBusiness }) => (isBusiness ? '0 auto' : '0')};

  ${down('lg')} {
    margin: 0 auto;
  }

  & > * {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const AuthTitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${down('md')} {
    flex-direction: column;
    gap: 8px;
  }

  h1 {
    margin-bottom: 0;
  }
`;

export const AuthCard = styled.div`
  padding: 36px 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
`;

export const AuthCardTitle = styled.label`
  display: flex;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.inactiveGrey};
  font-weight: 700;
  font-size: 18px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.moreBlack};
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const AuthItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
  padding: 0 4px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const AuthItemTitle = styled.label`
  position: relative;
  width: fit-content;
  font-weight: 500;
  font-size: 14px;

  &:after {
    content: '*';
    display: flex;
    position: absolute;
    top: -2px;
    right: -10px;
    color: ${({ theme }) => theme.colors.negative};
  }
`;

export const AuthItemDescription = styled.p`
  font-size: 12px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const AuthSubmitButtonSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.inactiveGrey};

  button {
    width: 200px;
    max-width: 100%;
  }
`;
