import React, {
  forwardRef,
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldError, useFormContext, Controller } from 'react-hook-form';
import { useIMask } from 'react-imask';
import styled from 'styled-components';
import { SvgCopyNew, SvgEyeClose, SvgEyeOpen } from '@partnerka-front/icons';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toast';
import { useTranslation } from 'react-i18next';
import { isNumberValid } from '@partnerka-front/utils';

interface InputStyleProps {
  widthIcons?: number;
  error?: boolean;
}

export const InputWrapper = styled.div<InputStyleProps>`
  position: relative;
  width: 100%;
  max-width: 360px;

  input {
    width: 100%;
    height: 50px;
    padding-top: 10px;
    padding-right: ${({ widthIcons }) => {
      if (widthIcons) return `${widthIcons}px`;
      return '16px';
    }};
    padding-left: 16px;
    border: 1px solid
      ${({ theme, error }) => (error ? theme.colors.negative : theme.colors.secondary)};
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: ${({ theme }) => theme.colors.headliner};

    &:focus,
    &.filled {
      & ~ label {
        top: 6px;
        font-size: 10px;
      }
    }
  }
`;

const InputLabel = styled.label<InputStyleProps>`
  position: absolute;
  top: 19px;
  left: 16px;
  color: ${({ theme, error }) => (error ? theme.colors.negative : theme.colors.secondary)};
  font-size: 12px;
  line-height: 12px;
  pointer-events: none;
  transition: 0.1s;
`;

const InputIcons = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 2px);
  padding-right: 16px;
  padding-left: 8px;
`;

const InputShowValue = styled.div`
  font-size: 0;
  line-height: 1;
  cursor: pointer;
`;

export const InputRecovery = styled.div`
  width: fit-content;
  margin: 10px 0 0 auto;

  a {
    color: ${({ theme }) => theme.colors.headliner};
    opacity: 0.6;
    line-height: 21px;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: ${({ theme }) => theme.colors.accent};
      opacity: 1;
    }
  }
`;

interface InputProps {
  label: string;
  defaultValue?: string;
  onChange?: (value: string, originalEvent?: React.FormEvent<HTMLInputElement>) => void;
  type?: 'text' | 'password';
  mask?: any;
  error?: FieldError;
  afterForm?: ReactNode;
  dataTestId?: string;
}

export function Input({
  label,
  defaultValue,
  onChange,
  mask,
  type,
  error,
  afterForm,
  dataTestId,
}: InputProps) {
  const [innerValue, setInnerValue] = useState(defaultValue ?? '');
  const refInput = React.createRef();

  const [widthIcons, setWidthIcons] = useState(0);
  const iconsRef = useRef<HTMLElement>();

  // Глазик и определение типа
  const [showValue, setShowValue] = useState(false);
  const inputType = useMemo(() => {
    if (type === 'password') {
      return !showValue ? 'password' : 'text';
    }
    return type;
  }, [showValue, type]);

  // Проверка на контент в инпуте
  const classList = [];
  if (innerValue) classList.push('filled');

  // IMask
  const [opts] = useState({
    mask,
    refInput,
    blocks: {
      '+': {
        mask: /.+/,
      },
    },
  });
  const { ref: maskRef } = useIMask(opts);

  // Handle onChange
  const handleInput: React.FormEventHandler<HTMLInputElement> = (e) => {
    const value = e.currentTarget.value;
    if (onChange) onChange(value, e);
    setInnerValue(value);
  };

  useEffect(() => {
    if (iconsRef.current?.clientWidth) setWidthIcons(iconsRef.current?.clientWidth);
  }, []);

  return (
    <div>
      <InputWrapper error={error && error.type === 'required'} widthIcons={widthIcons}>
        <input
          defaultValue={innerValue}
          onChange={handleInput}
          className={classList.join(' ')}
          type={inputType}
          ref={(mask ? maskRef : refInput) as RefObject<HTMLInputElement>}
          data-testid={dataTestId}
        />
        <InputLabel error={Boolean(error && error.type !== 'required')}>
          {error && error.type !== 'required' ? error.message : label}
        </InputLabel>
        {type === 'password' && (
          <InputIcons ref={iconsRef as RefObject<HTMLDivElement>}>
            {type === 'password' && (
              <InputShowValue onClick={() => setShowValue(!showValue)}>
                {showValue ? <SvgEyeOpen /> : <SvgEyeClose />}
              </InputShowValue>
            )}
          </InputIcons>
        )}
      </InputWrapper>
      {afterForm && <>{afterForm}</>}
    </div>
  );
}

interface ControlledInputProps extends InputProps {
  name: string;
}

export function ControlledInput({
  name,
  label,
  type,
  mask,
  afterForm,
  dataTestId,
}: ControlledInputProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <Input
            label={label}
            defaultValue={value}
            onChange={(_, e) => onChange(e)}
            type={type}
            mask={mask}
            error={error}
            afterForm={afterForm}
            dataTestId={dataTestId}
          />
        );
      }}
    />
  );
}

interface SimpleInputWrapperProps extends SimpleInputProps {
  iconsWidth?: number;
}

const SimpleInputWrapper = styled.div<SimpleInputWrapperProps>`
  position: relative;

  input {
    width: 100%;
    height: 40px;
    padding: ${({ iconsWidth }) => {
      if (iconsWidth) return `0 ${iconsWidth + 10}px 0 10px`;
      return '0 10px';
    }};
    border: 1px solid
      ${({ theme, error }) => (error ? theme.colors.negative : theme.colors.support)};
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.headliner};

    &::placeholder {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.support};
    }

    &:focus {
      border: 1px solid
        ${({ theme, error }) => (error ? theme.colors.negative : theme.colors.accent)};

      &::placeholder {
        font-size: 0;
      }
    }

    &[readonly]:focus {
      border: 1px solid ${({ theme }) => theme.colors.support};
    }

    &:disabled {
      border: 1px solid ${({ theme }) => theme.colors.inactiveGrey};
      background-color: transparent;
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

const SimpleInputLabel = styled.label`
  display: block;
  height: 20px;
  margin-bottom: 0;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const SimpleInputIcons = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: fit-content;
  height: 38px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 0 5px 5px 0;
  background: linear-gradient(270deg, #ffffff 43.23%, rgba(255, 255, 255, 0.1) 100%);

  & > * {
    cursor: pointer;
  }
`;

const Copy = styled.div`
  &:hover svg path {
    stroke: ${({ theme }) => theme.colors.accent};
    transition: 0.3s;
  }
`;

export const SimpleInputError = styled.div`
  padding: 2px 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.negative};
`;

interface SimpleInputProps {
  label?: string;
  defaultValue?: string;
  currentValue?: string;
  onChange?: (value: string, originalEvent?: React.FormEvent<HTMLInputElement>) => void;
  mode?: 'default' | 'copy';
  error?: FieldError | string | null;
  placeholder?: string;
  type?: 'text' | 'password' | 'number';
  disabled?: boolean;
}

// eslint-disable-next-line react/display-name,@typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line react/display-name
export const SimpleInput = forwardRef(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (
    {
      label,
      defaultValue,
      currentValue,
      onChange,
      mode,
      error,
      placeholder,
      type = 'text',
      disabled,
    }: SimpleInputProps,
    ref: RefObject<HTMLInputElement>
  ) => {
    const { t } = useTranslation(['common']);
    const [value, setValue] = useState(defaultValue ?? '');

    const [widthIcons, setWidthIcons] = useState(0);
    const iconsRef = useRef<HTMLElement>();

    // Глазик и определение типа
    const [showValue, setShowValue] = useState(false);
    const inputType = useMemo(() => {
      if (type === 'number') return 'text';
      if (type === 'password') {
        return !showValue ? 'password' : 'text';
      }
      return type;
    }, [showValue, type]);

    const handleInput: React.FormEventHandler<HTMLInputElement> = (e) => {
      const value = e.currentTarget.value;

      if (type !== 'number' || isNumberValid(value)) {
        if (onChange) onChange(value, e);
        setValue(value);
      }
    };

    const handleCopy = () => {
      copy(currentValue ? currentValue : value);
      toast.success(`${t('common.copied', { ns: 'common' })}`);
    };

    useEffect(() => {
      if (iconsRef.current?.clientWidth) setWidthIcons(iconsRef.current?.clientWidth);
    }, []);

    return (
      <div>
        {label && <SimpleInputLabel>{label}</SimpleInputLabel>}
        <SimpleInputWrapper error={error} iconsWidth={widthIcons}>
          <input
            value={currentValue ?? value}
            onChange={handleInput}
            ref={ref}
            readOnly={mode === 'copy'}
            placeholder={placeholder}
            type={inputType}
            disabled={disabled}
          />
          {(mode === 'copy' || type === 'password') && (
            <SimpleInputIcons ref={iconsRef as RefObject<HTMLDivElement>}>
              {type === 'password' && (
                <div onClick={() => setShowValue(!showValue)}>
                  {showValue ? <SvgEyeOpen /> : <SvgEyeClose />}
                </div>
              )}
              {mode === 'copy' && (
                <Copy onClick={handleCopy}>
                  <SvgCopyNew />
                </Copy>
              )}
            </SimpleInputIcons>
          )}
        </SimpleInputWrapper>
        {error && (
          <SimpleInputError>{typeof error === 'string' ? error : error?.message}</SimpleInputError>
        )}
      </div>
    );
  }
);

interface ControlledSimpleInputProps extends SimpleInputProps {
  name: string;
}

export function ControlledSimpleInput({
  name,
  label,
  placeholder,
  mode,
  error,
  type = 'text',
  disabled,
}: ControlledSimpleInputProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, ref }, fieldState: { error: FieldError } }) => {
        return (
          <SimpleInput
            label={label}
            placeholder={placeholder}
            currentValue={value}
            onChange={(_, e) => onChange(e)}
            ref={ref}
            mode={mode}
            error={FieldError ?? error}
            type={type}
            disabled={disabled}
          />
        );
      }}
    />
  );
}
