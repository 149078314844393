import React from 'react';
import styled from 'styled-components';
import { ReactNode } from 'react';
import { SvgNewWindow, SvgPlusRectangle } from '@partnerka-front/icons';
import { useNavigate } from 'react-router-dom';
import { UnknownFunction } from '@partnerka-front/types';

type ButtonVariants =
  | 'primary'
  | 'primary-inactive'
  | 'secondary'
  | 'basic'
  | 'active'
  | 'negative'
  | 'inactive'
  | 'accent'
  | 'support'
  | 'inactiveGrey';

interface ButtonStyleProps {
  variant?: ButtonVariants;
  linkIcon?: boolean;
  maxWidth?: 'full' | number;
}

const ButtonStyle = styled.button<ButtonStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  max-width: ${({ maxWidth }) => {
    if (maxWidth === 'full') return '100%';
    if (Number.isInteger(maxWidth)) return `${maxWidth}px`;
    return 'fit-content';
  }};
  height: 40px;
  ${({ linkIcon }) => {
    if (linkIcon) return 'padding: 0 20px;';
    return 'padding: 0 10px;';
  }}
  border: 1px solid ${({ theme, variant }) => {
    if (variant === 'active') return theme.colors.accent;
    if (variant === 'basic') return theme.colors.basic;
    if (variant === 'negative') return theme.colors.negative;
    if (variant === 'inactive') return theme.colors.support;
    if (variant === 'secondary') return theme.colors.secondary;
    if (variant === 'accent') return theme.colors.accent;
    if (variant === 'support') return theme.colors.support;
    if (variant === 'inactiveGrey') return theme.colors.inactiveGrey;
    if (variant === 'primary-inactive') return theme.colors.support;
    return theme.colors.headliner;
  }};
  border-radius: 5px;
  background-color: ${({ theme, variant }) => {
    if (variant === 'basic') return theme.colors.basic;
    if (variant === 'secondary') return theme.colors.secondary;
    if (variant === 'accent') return theme.colors.accent;
    if (variant === 'support') return theme.colors.support;
    if (variant === 'inactiveGrey') return theme.colors.inactiveGrey;
    return 'transparent';
  }};
  color: ${({ theme, variant }) => {
    if (variant === 'active') return `${theme.colors.accent}`;
    if (variant === 'inactive') return `${theme.colors.secondary}`;
    if (variant === 'secondary') return `${theme.colors.white}`;
    if (variant === 'negative') return theme.colors.negative;
    if (variant === 'accent') return `${theme.colors.white}`;
    if (variant === 'support') return theme.colors.white;
    return `${theme.colors.headliner}`;
  }};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    transform: scale(1.008);
  }

  &:active {
    transform: scale(0.98);
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.6;
    transform: none;
    cursor: default;
  }

  svg {
    width: 16px;
    min-width: 16px;

    path {
      stroke: ${({ theme, variant }) => {
        if (variant === 'active') return `${theme.colors.accent}`;
        if (variant === 'inactive') return `${theme.colors.secondary}`;
        if (variant === 'secondary') return `${theme.colors.white}`;
        if (variant === 'accent') return `${theme.colors.white}`;
        return `${theme.colors.headliner}`;
      }};
    }
  }
`;

export const ButtonAction = styled.div`
  display: block;
  border: none;
  background: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;

  &:active {
    transform: scale(0.98);
  }
`;

interface ButtonProps {
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: ButtonVariants;
  maxWidth?: 'full' | number;
  disabled?: boolean;
}

export function Button({ children, onClick, variant, maxWidth, disabled }: ButtonProps) {
  return (
    <ButtonStyle onClick={onClick} variant={variant} maxWidth={maxWidth} disabled={disabled}>
      {children}
    </ButtonStyle>
  );
}

interface ButtonHrefWrapperProps {
  maxWidth?: 'full' | number;
}

const ButtonHrefWrapper = styled.a<ButtonHrefWrapperProps>`
  width: 100%;
  max-width: ${({ maxWidth }) => {
    if (maxWidth === 'full') return '100%';
    if (Number.isInteger(maxWidth)) return `${maxWidth}px`;
    return 'fit-content';
  }};
  text-decoration: none;
`;

interface ButtonHrefProps extends ButtonProps {
  href: string;
}

export function ButtonHref({ children, href, variant, maxWidth }: ButtonHrefProps) {
  return (
    <ButtonHrefWrapper href={href} target={'_blank'} maxWidth={maxWidth}>
      <ButtonStyle maxWidth={'full'} variant={variant}>
        {children}
        <SvgNewWindow />
      </ButtonStyle>
    </ButtonHrefWrapper>
  );
}

const ButtonSubmitWrapper = styled.button<ButtonStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: ${({ maxWidth }) => {
    if (maxWidth === 'full') return '100%';
    if (Number.isInteger(maxWidth)) return `${maxWidth}px`;
    return '360px';
  }};
  height: 50px;
  border: none;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.accent};
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  transition: 0.3s;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.secondary};
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    box-shadow: 0 1px 7px ${({ theme }) => theme.colors.accent};
  }

  &:active {
    opacity: 0.8;
    transform: scale(0.98);
  }
`;

interface ButtonProps {
  children: ReactNode;
  disabled?: boolean;
  navLink?: string;
  dataTestId?: string;
}

export function ButtonSubmit({ children, disabled, navLink, maxWidth, dataTestId }: ButtonProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (navLink) navigate(navLink);
  };

  return (
    <ButtonSubmitWrapper
      disabled={disabled}
      onClick={handleClick}
      maxWidth={maxWidth}
      data-testid={dataTestId}>
      {children}
    </ButtonSubmitWrapper>
  );
}
